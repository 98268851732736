import React, { useEffect, useState } from "react";
import "./BookingForm.css";
import axios from "axios";
import { apiRequest } from "../Api";

const BookingForm = ({ id, page = [], roomType }) => {
  // State to hold the form input values and errors
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    roomType: "",
    roomAddress: "",
  });

  useEffect(() => {
    page.map((item, index) =>
      setFormData({ ...formData, roomAddress: item.roomAddress })
    );
  }, []);

  const [errors, setErrors] = useState({});

  // Function to handle room selection
  const handleRoomSelect = (room) => {
    setFormData({
      ...formData,
      roomType: `${room.type} - ${room.ac} - ${room.rent}`,
    });
    setErrors({ ...errors, roomType: "" });
  };

  // Validation function
  const validateForm = () => {
    let formErrors = {};
    if (!formData.name.trim()) formErrors.name = "Name is required.";
    if (!formData.email.trim()) {
      formErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = "Email is not valid.";
    }
    if (!formData.phone.trim()) {
      formErrors.phone = "Phone number is required.";
    } else if (!/^\d{10}$/.test(formData.phone)) {
      formErrors.phone = "Phone number should be 10 digits.";
    }
    if (!formData.roomType) formErrors.roomType = "Please select a room type.";

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = () => {
    if (validateForm()) {
      try {
        axios.post("http://localhost:4000/api/booking", formData);
        alert("Form submitted successfully!"); // Success alert
        resetForm(); // Reset the form after successful submission
      } catch (error) {
        console.error("Error saving contact", error);
        alert("There was an error submitting the form. Please try again."); // Error alert
      }
      // try {
      //   const data = await apiRequest("get", "booking", formData);
      //   alert("Form submitted successfully!");
      //   resetForm();
      // } catch (error) {
      //   console.error("Error saving contact", error);
      //   alert("There was an error submitting the form. Please try again."); // Error alert
      // }
    } else {
      alert("Please correct the form errors.");
    }
  };

  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      phone: "",
      roomType: "",
      roomAddress: "",
    });
  };

  return (
    <div className="container">
      <h2 className="text-center fst-italic">
        <span className="color">Book</span> Here
      </h2>

      {/* Name input field */}
      <div className="input-group mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Name"
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
        />
      </div>
      {errors.name && <p className="text-danger">{errors.name}</p>}

      {/* Email input field */}
      <div className="input-group mb-3">
        <input
          type="email"
          className="form-control"
          placeholder="Email"
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
        />
      </div>
      {errors.email && <p className="text-danger">{errors.email}</p>}

      {/* Phone input field */}
      <div className="input-group mb-3">
        <input
          type="number"
          className="form-control"
          placeholder="Phone Number"
          value={formData.phone}
          onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
        />
      </div>
      {errors.phone && <p className="text-danger">{errors.phone}</p>}

      {/* Room selection input */}
      <label htmlFor="basic-url" className="form-label text-secondary">
        Choose Your Room Type
      </label>
      <div className="input-group mb-3">
        <input
          type="text"
          className="form-control"
          value={formData.roomType}
          readOnly
        />
        <button
          className="btn btn-outline-danger dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          Room Type
        </button>
        <ul className="dropdown-menu w-60">
          {roomType.map((room, index) => (
            <li
              key={index}
              className="d-flex justify-content-around align-items-center room_list"
              onClick={() => handleRoomSelect(room)}
            >
              <p
                className="dropdown-item"
               
              >
                {room.type}
              </p>
              <p className="dropdown-item">{room.ac}</p>
              <p className="dropdown-item">{room.rent}</p>
            </li>
          ))}
        </ul>
      </div>
      {errors.roomType && <p className="text-danger">{errors.roomType}</p>}

      {/* Location input fields */}
      {page.length > 0 &&
        page.map((item, index) => (
          <div key={index} className="mb-3">
            <h6 className="text-secondary">Room Location</h6>
            <textarea
              className="form-control"
              value={item.roomAddress}
              placeholder={item.roomAddress}
              readOnly
            />
          </div>
        ))}

      {/* Form buttons */}
      <div className="btn_field text-center">
        <button className="form_btn" onClick={handleSubmit}>
          Confirm Details
        </button>
      </div>
    </div>
  );
};

export default BookingForm;
