import axios from "axios";
import React, { useState, useEffect } from "react";
import { apiRequest } from "../Api";

const Testimonials = () => {
  const [testimonialsData, setTestimonialData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    fetcher();
  }, []);

  const fetcher = async () => {
      try {
        const data = await apiRequest("get", "testimonials");
        setTestimonialData(data);
      } catch (error) {
        console.log("Failed to fetch testimonials", error)
      }
  };

  // Automatically cycle through testimonials
  useEffect(() => {
    const intervalId = setInterval(() => {
      handleNext();
    }, 3000); // Change testimonial every 3 seconds
    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, [currentIndex, testimonialsData.length]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === testimonialsData.length - 1 ? 0 : prevIndex + 1
    );
  };

  const testimonialInfo = testimonialsData[currentIndex];

  // Fallback in case there is no data yet
  if (!testimonialInfo) {
    return <div>Loading testimonials...</div>;
  }

  return (
    <section className="text-center section_gap">
      <div className="container position-relative">
        {/* Section Header */}
        <div className="mb-5">
          <h1 className="color content">Testimonials</h1>
          <div
            className="mx-auto my-3"
            style={{ width: "100px", height: "5px", backgroundColor: "black" }}
          ></div>
        </div>

        {/* Testimonial Content */}
        <div className="p-4 testimonial_content" data-aos="fade-up">
          {/* Star Rating */}
          <div className="mb-4">
            {[...Array(testimonialInfo.rating)].map((_, i) => (
              <img
                key={i}
                src="https://assets.website-files.com/6458c625291a94a195e6cf3a/6458c625291a947e84e6cf91_Vector.svg" // replace this with your star image path
                alt="star"
                className="mr-2"
                style={{ width: "20px" }}
              />
            ))}
          </div>

          {/* Testimonial Text */}
          <p className="lead mx-auto mb-4" style={{ maxWidth: "600px" }}>
            {testimonialInfo.content}
          </p>

          {/* Testimonial Author */}
          <img
            className="rounded-circle"
            alt="author"
            src={`http://localhost:4000/Images/${testimonialInfo.image}`}
            style={{ width: "80px", height: "80px" }}
          />
          <p className="font-weight-bold mt-3">{testimonialInfo.name}</p>
          <p className="text-muted">{testimonialInfo.role}</p>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
