import React, { useEffect, useState } from "react";
import "./SectionOne.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import { LiaMaleSolid } from "react-icons/lia";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import { apiRequest } from "../Api";

const SectionOne = ({ page, setPage }) => {
  const [propertyList, setPropertyList] = useState([]);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Show 3 slides at a time
    slidesToScroll: 3, // Scroll by 3 slides
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  useEffect(() => {
    detailsFetcher();
  }, []);
  const detailsFetcher = async () => {
    try {
      const data = await apiRequest("get", "hostels");
      setPropertyList(data);
    } catch (error) {
      console.error("Failed to fetch property list", error)
    }
  };
  const sendingData = (dd) => {
    setPage([dd]);
  };
  return (
    <div className="sec_1_main_page container section_gap">
      <h3 className="text-center sec_1_main_page_title">
        <span className="color">Chennai's</span> Most Secure PG Homes <br /> for
        Peaceful Living
      </h3>
      <div className="sec_1_locaitons_card mt-3 pt-2" data-aos="fade-up">
        {/* First Set of Properties */}
        <Slider {...settings}>
          {propertyList.map((item) => (
            <div className="p-2 sec_1_box" key={item.id}>
              <Link
                className="text-dark"
                to="/room_details"
                onClick={() => sendingData(item)}
              >
                <img
                  src={`http://localhost:4000/${item.hostelImage}`}
                  alt="img"
                />
                <div className="sec_1_box_body">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h4 className="sec_1_box_title fst-italic">
                        <span className="color">{item.brand}</span> Hi Tech
                      </h4>
                      <h6 className="sec_1_box_text">{item.address}</h6>
                    </div>
                    <div className="field_btn">
                      <div className="btn_male">
                        <LiaMaleSolid className="mb-1" />
                        {item.gender}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="line"></div>
                <div className="d-flex justify-content-between">
                  <div className="sec_1_box_body">
                    <h6 className="sec_1_box_text">Rent starts from</h6>
                    <h5 className="sec_1_box_title">
                    ₹ {item.startingRent}/-
                    </h5>
                  </div>

                  <div className="field_btn mt-3">
                    <button className="btn_booknow">View More</button>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default SectionOne;
