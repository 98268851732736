import React, { useEffect, useState } from "react";
import "./Blogs.css";
import Title from "../../Title/Title";
import { Assets } from "../../../Assets/Assets";
import axios from "axios";
import { apiRequest } from "../../Api";

const Blogs = () => {
  const [blogData, setBlogData] = useState([]);

  useEffect(() => {
    fetcher();
  }, []);

  const fetcher = async () => {
    try {
      const data = await apiRequest("get", "blogs");
      setBlogData(data);
    } catch (error) {
      console.log("Failed to fetch blogs", error);
    }
  };

  return (
    <div className="blogs">
      <div className="position-relative">
        <div>
          <img
            src={Assets.bck_2}
            className="title_bck_img"
            alt="background_img"
          />
          <Title title={"Our"} us={"Blogs"} />
        </div>
      </div>

      {/* Section two starts */}
      <div className="container mt-5">
        <div className="row">
          {blogData
            .slice()
            .reverse()
            .map((blog) => (
              <div key={blog.id} className="col-md-4 mb-4">
                <a
                  href={blog.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-reset text-decoration-none"
                >
                  <div className="card card_blog_1">
                    <img
                      src={"http://localhost:4000/Images/" + blog.image}
                      alt={blog.title}
                      className="card-img-top"
                    />
                    <div className="card-body card_blog_body_1">
                      <h4 className="card-title">{blog.title}</h4>
                      <h6 className="card-subtitle">{blog.subtitle}</h6>
                      <p className="card-text">{blog.subContent}</p>
                    </div>
                  </div>
                </a>
              </div>
            ))}
        </div>
      </div>
      {/* Section two ends */}
    </div>
  );
};

export default Blogs;
