import React, { useState } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Assets } from "../../../Assets/Assets";
import Title from "../../Title/Title";
import axios from "axios";

const ContactSection = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    name: false,
    email: false,
    number: false,
    message: false,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Validate form data
  const validateForm = () => {
    const newErrors = {
      name: formData.name === "",
      email: !/\S+@\S+\.\S+/.test(formData.email),
      number: formData.number.length !== 10,
      message: formData.message === "",
    };
    setErrors(newErrors);
    return !Object.values(newErrors).includes(true);
  };

  // Handle form submission for creating a new contact message
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        await axios.post("http://localhost:4000/api/contact", formData);
        alert("Form submitted successfully!"); // Success alert
        resetForm(); // Reset the form after successful submission
      } catch (error) {
        console.error("Error saving contact", error);
        alert("There was an error submitting the form. Please try again."); // Error alert
      }
    } else {
      alert("Please correct the form errors.");
    }
  };

  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      number: "",
      message: "",
    });
    setErrors({
      name: false,
      email: false,
      number: false,
      message: false,
    });
  };

  return (
    <div className="position-relative">
      <div>
        <img
          src={Assets.bck_2}
          className="title_bck_img img-fluid"
          alt="background_img"
        />
        <Title title={"Contact"} us={"Us"} />
      </div>
      <section className="container my-5 border py-4 shadow-lg p-3 mb-5 bg-body rounded">
        <div className="text-center mb-5">
          <h2 className="font-weight-bold fst-italic">Get In Touch With Us</h2>
          <p className="d-none d-md-block text-muted">
            For more information about our PG's & Guest house, please feel free
            to drop us an email.
          </p>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-5 col-md-6">
            <div className="d-flex align-items-start mb-4">
              <i className="bi bi-geo-alt-fill me-3"></i>
              <div>
                <h5 className="font-weight-bold">Address</h5>
                <p>Vadapalani / Ramapuram / Kodambakkam</p>
              </div>
            </div>
            <div className="d-flex align-items-start mb-4">
              <i className="bi bi-telephone-fill me-3"></i>
              <div>
                <h5 className="font-weight-bold">Phone</h5>
                <p>Mobile: +(91) 98848 25258 / 98402 34475</p>
              </div>
            </div>
            <div className="d-flex align-items-start mb-4">
              <i className="bi bi-envelope-fill me-3"></i>

              <div>
                <h5 className="font-weight-bold">E-Mail</h5>
                <p>brindhavanamhostel@gmail.com</p>
              </div>
            </div>
          </div>

          <div className="col-lg-5 col-md-6 shadow-sm p-3 mb-5 bg-body rounded">
            <form className="form-group mb-4" onSubmit={handleSubmit}>
              <label htmlFor="name" className="font-weight-bold mb-2">
                Your Name
              </label>
              <input
                type="text"
                id="name"
                className={`form-control ${errors.name ? "is-invalid" : ""}`}
                placeholder="Name"
                value={formData.name}
                name="name"
                onChange={handleInputChange}
              />
              {errors.name && (
                <div className="invalid-feedback">Please enter your name.</div>
              )}

              <label htmlFor="email" className="font-weight-bold my-2">
                Email Address
              </label>
              <input
                type="email"
                id="email"
                className={`form-control ${errors.email ? "is-invalid" : ""}`}
                placeholder="Email Address"
                value={formData.email}
                name="email"
                onChange={handleInputChange}
              />
              {errors.email && (
                <div className="invalid-feedback">
                  Please enter a valid email address.
                </div>
              )}

              <label htmlFor="phone" className="font-weight-bold my-2">
                Contact Number
              </label>
              <input
                type="number"
                id="phone"
                className={`form-control ${errors.number ? "is-invalid" : ""}`}
                placeholder="Contact Number"
                value={formData.number}
                name="number"
                onChange={handleInputChange}
              />
              {errors.number && (
                <div className="invalid-feedback">
                  Please enter a valid 10-digit phone number.
                </div>
              )}

              <label htmlFor="message" className="font-weight-bold my-2">
                Message
              </label>
              <textarea
                id="message"
                className={`form-control ${errors.message ? "is-invalid" : ""}`}
                rows="3"
                placeholder="Message"
                value={formData.message}
                name="message"
                onChange={handleInputChange}
              />
              {errors.message && (
                <div className="invalid-feedback">
                  Please enter your message.
                </div>
              )}

              <button type="submit" className="form_btn px-4 mt-3">
                Submit
              </button>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactSection;
