import React, { useEffect } from "react";
import "./RoomDetails.css";
import { CiLocationOn } from "react-icons/ci";
import Amenities from "../Amenities/Amenities";
import { IoWalletOutline } from "react-icons/io5";
import BookingForm from "../BookingForm/BookingForm";
import Slider from "react-slick"; // Import react-slick
import "slick-carousel/slick/slick.css"; // Slick styles
import "slick-carousel/slick/slick-theme.css";

const RoomDetails = ({ page, setPage }) => {
  const generateGoogleMapLink = (address) => {
    return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      address
    )}`;
  };
  // On component mount, check if there is page data stored in localStorage
  useEffect(() => {
    const storedPageData = localStorage.getItem("pageData");
    if (storedPageData) {
      setPage(JSON.parse(storedPageData)); // Restore the page data if available
    }
  }, [setPage]);

  // Whenever the page data changes, save it to localStorage
  useEffect(() => {
    if (page.length > 0) {
      localStorage.setItem("pageData", JSON.stringify(page));
    }
  }, [page]);

  // Slick slider settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
  };

  return (
    <div className="container my-5">
      <div className="d-flex room_details justify-content-between">
        <div className="room_info_box">
          {page.map((item, index) => (
            <div key={index}>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h4 className="fst-italic">
                    <span className="color">{item.brand}</span> Hi Tech
                  </h4>
                  <p className="address">{item.roomAddress}</p>
                </div>
                <div className="icon_map">
                  <a href={generateGoogleMapLink(item.roomAddress)}>
                    <CiLocationOn className="fs-2 color" />
                  </a>
                </div>
              </div>

              {/* React Slick Carousel */}
              <Slider {...settings} className="w-[200px]">
                {item.roomImages.map((image) => (
                  <div key={image.id}>
                    <img
                      //   src={image.url}
                      src={`http://localhost:4000/${image}`}
                      alt="Room"
                      className="w-full h-32 object-cover"
                    />
                  </div>
                ))}
              </Slider>

              <h2>
                About the <span className="color">Property</span>
              </h2>
              <p className="content_style">{item.aboutInfo}</p>
              <h2>
                Room <span className="color">Details</span>
              </h2>
              <p className="content_style">All room type have some variants that are larger in size or have extra Amenities.</p>

              <div className="imp_info_tag">
                <h5 className="text-light text-center fst-italic mt-2">
                  <IoWalletOutline className="fs-3 me-2 mb-1" />
                  No delays on your monthly rent
                </h5>
              </div>
              <h2>
                Food <span className="color">Menu</span>
              </h2>
              <p className="fst-italic pb-0">{item.foodInfo}</p>
              <div className="d-flex justify-content-between">
                <p>{item.breakfastTime}</p>
                <p>{item.lunchTime}</p>
                <p>{item.dinnerTime}</p>
              </div>
              <Amenities />
              <h2>
                Pay For What <span className="color">You Use</span>
              </h2>
              <h6>Electricity Charges: Based On Actuals</h6>
              <p className="content_style">
                As applicable under the relevant State Electricity Board
                Charges, plus necessary convenience charges wherever
                applicable.Electricity charges are not included in the monthly
                payment.
              </p>
              <h6>Maintenance Charges: NIL</h6>
              <p className="content_style">
                No Maintenance Charges. Any loss or damage to the Property is
                subjected to the deduction in Security Deposit.
              </p>
            </div>
          ))}
        </div>
        <div className="room_booking_form">
          {page.map((item, index) => (
            <BookingForm
              key={index}
              id={item.id}
              page={page}
              roomType={item.roomType}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default RoomDetails;
